/*
 * NOTE: use a six-character hex code for all colors to allow alpha channel
 * adjustment without adding extra vars and/or a color manipulation lib.
 *
 * Example:
 *    // use the brand color at 25% opacity
 *    border-color: ${colors.brand}40;
 */

/* eslint-disable max-len */
export const colors = {
  salmon: '#F39D91',
  purple: '#B767F6',
  orange: '#FB7349',
  taupe: '#DBB989',
  eggplant: '#350048',
  forest: '#032E2B',
  lilac: '#8c65b3',
  cobalt: '#0067B3',
  green: '#108579',
  neutral: '#FFFBF6',
  text: '#000000',
}

export const base = {
  value: 8
}

export const spacing = {
  xs: base.value,
  sm: base.value * 2,
  md: base.value * 4,
  lg: base.value * 8,
  xl: base.value * 10
}

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 768,
  desktop: 1100,
  hd: 1300
}

export const radius = {
  default: 2,
  large: 4
}

export const sansStack = [
  'Poppins',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Open Sans',
  'Helvetica Neue',
  'sans-serif'
].join()

export const serifStack = [
  'Orelo',
  'Stint Ultra Expanded',
  'Georgia',
  'serif'
].join()

export const fonts = {
  poppins: sansStack,
  orelo: serifStack,
}

export const grid = {
  width: 1312
}
