export const validateEmail = email => {
  /* eslint-disable max-len */
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validateURL = url => {
  const re = /^[a-zA-Z]{3,15}\.[a-zA-Z)[^a-zA-Z]/
  return re.test(url)
}

export const smoothAnch = () => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function scroll (e) {
      e.preventDefault()
      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      })
    })
  })
}

export const checkIE = () => {
  if (typeof window !== 'undefined') {
    return !!document.documentMode
  }
  return false
}

// This is our default list of props we don't want to forward.
// If there is one // off ones they can be passed as an argument
// shouldForwardList(prop, ['src']) or shouldForwardList(prop)

export const shouldForwardList = (prop, custom) => {
  return !['rows', 'cols', 'src', custom].includes(prop)
}

export const throttled = (delay, fn) => {
  let lastCall = 0
  return function (...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

export const throttleIM = (func, interval) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = false
    }
    if (!timeout) {
      func.apply(context, args)
      timeout = true
      setTimeout(later, interval)
    }
  }
}
