import styled from '@emotion/styled'
import { colors, fonts, base, breakpoints } from '../../styles/variables'

export const TextContainer = styled('div')`
  margin: 0;
  margin-left: 0;
  margin-right: auto;
  max-width: ${ base.value * 120 }px;
  width: 100%;
  text-align: ${ props => (props.ta ? props.ta : 'left') };
  &.career {
    > p {
      margin-bottom: 4rem;
    }
  }
  &.bio {
      margin-top: 1rem;
  }
`

export const H1 = styled('h1')`
  font-size: calc(${ base.value * 10 }px + 3vmin);
  line-height: ${ base.value * 12 }px;
  max-width: none;
  font-family: ${ fonts.orelo };
  font-weight: 300;
  letter-spacing: 0em;
  margin: 0 0 ${ base.value * 4 }px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 12 }px;
    line-height: ${ base.value * 11 }px;
  }
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    font-size: ${ base.value * 6.5 }px;
    line-height: ${ base.value * 7 }px;
  }
`

export const H2 = styled('h2')`
  font-family: ${ fonts.orelo };
  font-size: calc(${ base.value * 7 }px + 3vmin);
  line-height: ${ base.value * 10 }px;
  font-weight: 300;
  letter-spacing: 0em;
  margin: 0 0 ${ base.value * 4 }px;
  max-width: 960px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 7 }px;
    line-height: ${ base.value * 7 }px;
  }
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    font-size: ${ base.value * 5 }px;
    line-height: ${ base.value * 5.5 }px;
  }
`

export const H3 = styled('h3')`
  font-family: ${ fonts.orelo };
  font-size: calc(${ base.value * 4 }px + 2vmin);
  line-height: ${ base.value * 7 }px;
  font-weight: 300;
  letter-spacing: 0em;
  margin: 0 0 ${ base.value * 4 }px;
  /* max-width: 600px; */
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 5 }px;
    line-height: ${ base.value * 5.5 }px;
  }
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    font-size: ${ base.value * 3.5 }px;
    line-height: ${ base.value * 4 }px;
  }
`

export const H4 = styled('h4')`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 2.25 }px;
  line-height: ${ base.value * 3.25 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  margin: 0 0 ${ base.value * 2 }px;
  text-transform: uppercase;
  max-width: 600px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 2 }px;
    line-height: ${ base.value * 3 }px;
  }
`

export const H5 = styled('h5')`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 1.75 }px;
  line-height: ${ base.value * 2.5 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  margin: 0 0 ${ base.value * 1 }px;
  text-transform: uppercase;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 1.5 }px;
    line-height: ${ base.value * 2.5 }px;
  }
`

export const Blockquote = styled('blockquote')`
  p {
    font-family: ${ fonts.orelo };
    font-size: ${ base.value * 10 }px;
    line-height: ${ base.value * 10 }px;
    font-weight: 300;
    letter-spacing: 0em;
    margin: 0 0 ${ base.value * 4 }px;
    max-width: 960px;  
    @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 6 }px;
    line-height: ${ base.value * 6 }px;
    }
    @media screen and (max-width: ${ breakpoints.phablet }px) {
      font-size: ${ base.value * 5 }px;
      line-height: ${ base.value * 5 }px;
    }
  }
`

export const LargeParagraph = styled('p')`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 2.75 }px;
  line-height: 2;
  font-weight: 300;
  letter-spacing: 0.1em;
  margin: 0 0 ${ base.value * 3 }px;
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    font-size: ${ base.value * 2 }px;
  }
`

export const Paragraph = styled('p')`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 2.25 }px;
  line-height: 1.5;
  font-weight: 300;
  letter-spacing: 0.1em;
  margin: 0 0 ${ base.value * 3 }px;
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    font-size: ${ base.value * 1.75 }px;
  }
`

export const HR = styled('hr')`
  background: ${ colors.text };
  height: 1px;
  width: 100%;
  border: 0;
  margin: 0;
`

export const UnorderedList = styled('ul')`
  color: ${ colors.text };
  font-family: ${ fonts.poppins };
  margin: 0 0 3rem;
  padding-left: 2rem;
  li {
    margin: 0 0 1rem;
    position: relative;
    &:before {
      content: "-";
      left: -1em;
      top: 0.5em;
      position: absolute;
    }
    p {
      margin: 0;
    }
  }

  a {
    color: ${ colors.text };
  }
`
